import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, CmsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { CustomAddToCartService } from './custom-add-to-cart/custom-add-to-cart.service';
import { CustomInfluenzaService } from './custom-influenza/custom-influenza.service';
import { AccountType } from './custom-user-registration/registration.constants';
import { EmailPopupComponent } from './shared/modules/email-popup/email-popup/email-popup.component';
import { ExternalsiteAlertComponent } from './shared/modules/externalsite-alert/externalsite-alert/externalsite-alert.component';
import { SharedService } from './shared/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'frspartacusstore';
  eventReference: any;
  isUserLoggedIn = false;
  pageName: string;
  pageId: string;
  modalOption: NgbModalOptions = {};
  emailConsent: any;
  userId: any;
  accountType: any;
  isAsmEnabled: any;
  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  emailConsentEnabled: boolean;
  accountDataSub: Subscription;
  activeSeasonSubscriber: Subscription;

  constructor(
    private readonly renderer: Renderer2,
    private readonly pageDate: CmsService,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    private readonly router: RoutingService,
    private readonly route: Router,
    private readonly user: UserAccountFacade,
    private readonly modalService: NgbModal,
    private readonly ref: ChangeDetectorRef,
    private readonly csAgentAuthService: CsAgentAuthService,
    private readonly customAddToCartService: CustomAddToCartService,
    private readonly influenzaService: CustomInfluenzaService,
    private _renderer2: Renderer2,

    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    console.log('upg');
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }
    });
    this.addSnippet();
    this.isLoggedIn.subscribe((resLoggedIn) => {
      if (resLoggedIn) {
        this.user.get().subscribe((userRes) => {
          if (userRes !== undefined) {
            const qresult: any = userRes;
            this.emailConsent = qresult?.emailConsent;
            this.userId = qresult?.userName;
            if (qresult?.akamaiId) {
              localStorage.setItem('user_id', qresult?.akamaiId);
            }
            sessionStorage.setItem('userId', this.userId);
            localStorage.setItem('user_login_id', this.userId);
            localStorage.setItem('user_type', 'Internal customer');
            this.accountType = qresult?.accountType;
            sessionStorage.setItem('accountType', this.accountType);

            localStorage.setItem('user_function', qresult?.role);
            this.isUserLoggedIn = true;
            if (this.isUserLoggedIn) {
              this.customAddToCartService.getUserAccountDataFromDb(this.userId);
              this.accountDataSub =
                this.customAddToCartService.userAccountData.subscribe(
                  (accountdata) => {
                    localStorage.setItem(
                      'user_account_id',
                      accountdata?.res?.defaultB2BUnit?.uid
                    );
                    sessionStorage.setItem(
                      'accountType',
                      accountdata?.res?.defaultB2BUnit?.hospital
                        ? AccountType.Hospital
                        : AccountType.Pharmacy
                    );
                    this.accountDataSub?.unsubscribe();
                  }
                );
              this.influenzaService.getActiveSeasonsFromDb(this.userId);
              this.activeSeasonSubscriber =
                this.influenzaService.activeSeaons.subscribe((data) => {
                  let activeFluSeason = true;
                  const todayDate = new Date();
                  const startDate = data.categoryData[0]?.startDate;
                  const endDate = data.categoryData[0]?.endDate;
                  if (
                    todayDate > new Date(endDate) ||
                    todayDate < new Date(startDate) ||
                    !data.categoryData?.[0]
                  ) {
                    activeFluSeason = false;
                  }
                  sessionStorage.setItem(
                    'activeFluSeason',
                    String(activeFluSeason)
                  );
                  this.activeSeasonSubscriber?.unsubscribe();
                });
            }
            this.pageDate.getCurrentPage().subscribe((res) => {
              this.pageName = res?.name;
              this.pageId = res?.pageId;
              if (res) {
                if (this.pageName === 'Homepage') {
                  this.router.goByUrl('my-account/account-overview');
                } else if (this.pageId === 'LoginPageReference') {
                  this.router.goByUrl('my-account/account-overview');
                }
              }
            });
          } else {
            this.isUserLoggedIn = false;
            this.emailConsentEnabled = false;
          }
        });
      } else {
        localStorage.removeItem('user_login_id');
        localStorage.removeItem('user_id');
        sessionStorage.removeItem('userId');
        localStorage.removeItem('user_type');
        localStorage.removeItem('user_account_id');
        localStorage.removeItem('user_function');
      }
    });

    this.renderer.listen('document', 'click', (event) => {
      $('.SiteLinks').find('a').addClass('login-link');
      if (event.target.classList.contains('close-banner')) {
        const elementArray = document.getElementsByClassName('Section1');
        const displayBanner = document.getElementsByClassName(
          'ContentSlotHeaderBannerOrdersListing'
        );
        const accImpBanner = document.getElementsByClassName(
          'SanofiAccountOverviewBannerSlot'
        );
        const accIndirectBanner = document.getElementsByClassName(
          'account-overview-banner'
        );
        for (let item = 0; item < elementArray.length; item++) {
          elementArray[item].className += ' bannerHide';
        }
        for (let item = 0; item < displayBanner.length; item++) {
          displayBanner[item].className += ' bannerHide';
        }
        for (let item = 0; item < accImpBanner?.length; item++) {
          accImpBanner[item].className += ' bannerHide';
        }
        for (let item = 0; item < accIndirectBanner?.length; item++) {
          accIndirectBanner[item].className += ' bannerHide';
        }
      }
      if (event.target.classList.contains('app-navigation-links')) {
        if (event.target.type === 'external') {
          this.eventReference = event.target.rel;
          const modalRef = this.modalService.open(ExternalsiteAlertComponent, {
            windowClass: 'externalsite-modal',
            backdrop: 'static',
            keyboard: false,
          });
          modalRef.componentInstance.eventReference = this.eventReference;
        } else if (event.target.type === 'internal') {
          window.open(event.target.rel);
        }
      }
      const hamburger = document.getElementsByClassName('cx-hamburger');
      const hideSiteContext = document.getElementsByClassName('SiteContext');
      const hideSiteLink = document.getElementsByClassName('SiteLinks');
      const hideSearchBox = document.getElementsByClassName('SearchBox');
      const hideSiteLogo = document.getElementsByClassName('SiteLogo');
      const hideMiniCart = document.getElementsByClassName('MiniCart');
      const hideSiteLogin = document.getElementsByClassName('SiteLogin');
      let expandedOption;
      for (let i = 0; i < hamburger.length; i++) {
        expandedOption = hamburger[i].classList.contains('is-active');
      }
      if (expandedOption) {
        for (let i = 0; i < hideSiteContext.length; i++) {
          hideSiteContext[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSiteLink.length; i++) {
          hideSiteLink[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSearchBox.length; i++) {
          hideSearchBox[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSiteLogo.length; i++) {
          hideSiteLogo[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideMiniCart.length; i++) {
          hideMiniCart[i].classList.add('noDisplay');
        }
        for (let i = 0; i < hideSiteLogin.length; i++) {
          hideSiteLogin[i].classList.add('noDisplay');
        }
      } else {
        for (let i = 0; i < hideSiteContext.length; i++) {
          hideSiteContext[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSiteLink.length; i++) {
          hideSiteLink[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSearchBox.length; i++) {
          hideSearchBox[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSiteLogo.length; i++) {
          hideSiteLogo[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideMiniCart.length; i++) {
          hideMiniCart[i].classList.remove('noDisplay');
        }
        for (let i = 0; i < hideSiteLogin.length; i++) {
          hideSiteLogin[i].classList.remove('noDisplay');
        }
      }
    });
  }
  emailConsentContent() {
    if (!sessionStorage.getItem('emailPopUp')) {
      this.sharedService.getEmailConsent(this.userId);
      this.emailConsentEnabled = true;
      this.sharedService.emailConsent$.subscribe((result) => {
        if (result) {
          if (
            !this.modalService.hasOpenModals() &&
            result.emailConsentEnabled === true
          ) {
            this.modalOption.backdrop = 'static';
            this.modalOption.keyboard = false;
            this.modalOption.windowClass = 'email-popup-modal';
            const modalRef = this.modalService.open(
              EmailPopupComponent,
              this.modalOption
            );
            modalRef.componentInstance.emailConsentObj = result;
            modalRef.componentInstance.userId = this.userId;
            this.sharedService.emailConsent.next(false);
          }
        }
      });
    }
  }
  addSnippet() {
    this.sharedService.getsnippetAPI();
    this.sharedService.snippetData$.subscribe((result) => {
      if (result.snippetConfigs && result.snippetConfigs.length > 0) {
        const snippetCommentStart = this._renderer2.createComment(
          'BEGIN QUALTRICS WEBSITE FEEDBACK SNIPPET'
        );
        const snippetCommentEnd = this._renderer2.createComment(
          'END WEBSITE FEEDBACK SNIPPET'
        );
        for (const config of result.snippetConfigs) {
          const snippetValue = config.value.replace(/\\n/g, '');
          const script = this._renderer2.createElement('script');
          script.type = `text/javascript`;
          script.text = snippetValue;
          this._renderer2.appendChild(this.document.head, snippetCommentStart);
          this._renderer2.appendChild(this.document.head, script);
          const snippetDiv = this._renderer2.createElement('div');
          snippetDiv.id = config.divId;
          const snippetDivComment = this._renderer2.createComment(
            'DO NOT REMOVE-CONTENTS PLACED HERE'
          );
          snippetDiv.appendChild(snippetDivComment);
          this._renderer2.appendChild(this.document.head, snippetDiv);
          this._renderer2.appendChild(this.document.head, snippetCommentEnd);
        }
      }
    });
  }
}
