import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import * as _ from 'lodash';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CustomInfluenzaService } from '../custom-influenza.service';

@Component({
  selector: 'app-flu-charts',
  templateUrl: './flu-charts.component.html',
  styleUrls: ['./flu-charts.component.scss'],
})
export class FluChartsComponent implements OnInit, OnDestroy {
  orderHistory: any = [];
  productsArray: any = [];
  totalDoses: number = 0;
  totalShippedQuantity: number = 0;
  totalWaitingQuantity: number = 0;
  totalCancelledQuantity: number = 0;
  chartTableArray: any = [];
  chartsBgColorArray: any = [];
  openedEntries: any = [];
  consignmentEntriesObjArray: any = [];
  statusInChrtsDisplay: any = 'livrées';
  consignmentProducts: any = new Map();
  scheduleToShip: any = [];
  cancelledScheduleToShip: any = [];
  previousSeasonArray: any =
    this.sharedService.getSessionStorage('previousSeasons');
  showWeek: boolean = true;
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
  };
  EN_ATTENTE = 'En attente';
  QUANTITY_STATUS = {
    IN_PREP: 'En cours de préparation'
  }

  public chartColors: any[] = [{ backgroundColor: this.chartsBgColorArray }];
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: any = [];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] =
    [
      {
        data: [],
      },
    ];

  dosesInChartDisplay: any = 0;
  url: any;
  urlPath: any;
  chartSubscriber: any;

  // New
  public doughnutChartLabelsNew: string[] = [];
  public newDoughnutData: any = [];
  public newDoughnutLabel: any = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly ref: ChangeDetectorRef,
    private readonly sharedService: SharedService,
    private readonly influenzaService: CustomInfluenzaService
  ) {}

  ngOnInit() {
    this.url = this.activatedRoute?.url;
    this.urlPath = this.url?._value[this.url?._value.length - 1]?.path;
    this.chartSubscriber = this.influenzaService.detailedReservation.subscribe(
      (res) => {
        if (res) {
          this.chartDataFormation(res);
        }
      }
    );
  }

  ngOnDestroy() {
    this.chartSubscriber?.unsubscribe();
  }

  chartDataFormation(data) {
    this.chartsBgColorArray = [];
    this.chartColors = [{ backgroundColor: this.chartsBgColorArray }];
    this.orderHistory = [];
    this.doughnutChartData = [];

    this.newDoughnutData = [];
    this.doughnutChartLabels = [];
    this.newDoughnutLabel = [];
    this.chartTableArray = [];
    this.consignmentEntriesObjArray = [];
    this.openedEntries = [];
    this.totalWaitingQuantity = 0;
    this.totalShippedQuantity = 0;
    this.totalCancelledQuantity = 0;
    this.orderHistory.push(data);
    this.totalDoses =
      data?.totalDosesCount - (data?.cancelledTotalQuantity || 0);
    this.dosesInChartDisplay = this.totalDoses || this.totalDoses;
    this.totalShippedQuantity =
      data?.shippedTotalQuantity || this.totalShippedQuantity;
    this.totalCancelledQuantity =
      data?.cancelledTotalQuantity || this.totalCancelledQuantity;
    data?.deliverySchedules.forEach((elem) => {
      if (elem.quantityStatus === this.QUANTITY_STATUS.IN_PREP) {
        this.totalWaitingQuantity = elem.requestedQuantity;
      }
    });

    data?.unconsignedEntries.forEach((elem) => {
      let openEntryObj = {};
      openEntryObj = {
        prodName: elem?.product?.name,
        quantity: elem?.quantity,
        entryNumber: elem?.entryNumber,
        status: null,
        cancellationReason: null,
      };
      this.openedEntries.push(openEntryObj);
    });

    data?.consignments
      ?.sort((a, b) => {
        const dateA = a.statusDate ? new Date(a.statusDate).getTime() : null;
        const dateB = b.statusDate ? new Date(b.statusDate).getTime() : null;

        if (dateA === null) {
          return 1; // Place consignments with null statusDate last
        }
        if (dateB === null){
           return -1; // Place consignments with null statusDate first
        }
        return dateA - dateB; // Ascending order by statusDate
      })
      ?.forEach((consignment) => {
        let consignmentEntryObj = {};
        const week = consignment?.week;
        consignment.entries.forEach((elem) => {
          consignmentEntryObj = {
            status: elem?.status,
            prodName: elem?.orderEntry?.product?.name,
            quantity: elem?.shippedQuantity,
            entryNumber: elem?.orderEntry?.entryNumber,
            cancellationReason: elem?.rejectionReason,
            week: week,
          };
          this.consignmentEntriesObjArray.push(consignmentEntryObj);
        });
      });
    this.consignmentProducts = _.mapValues(
      _.groupBy(
        this.consignmentEntriesObjArray,
        (consignmentEntry) => consignmentEntry.status
      ),
      (clist) =>
        clist.map((consignmentEntry) =>
          _.omit(consignmentEntry, consignmentEntry.status)
        )
    );

    this.consignmentEntriesObjArray?.forEach((elem) => {
      const sameEntry = this.openedEntries.find(
        (openedEntry) => openedEntry.entryNumber === elem.entryNumber
      );
      if (sameEntry !== undefined) {
        sameEntry.quantity -= elem.quantity;
        if (!sameEntry.quantity) {
          this.openedEntries.splice(this.openedEntries.indexOf(sameEntry), 1);
        }
      }
    });
    if (this.openedEntries.length > 0) {
      this.consignmentProducts[this.EN_ATTENTE] = this.openedEntries;
    } else {
      this.dosesInChartDisplay = 0;
    }
    const schedules = [];
    const cancelledSchedules = [];
    //Schedule to ship
    const delSched = _.mapValues(
      _.groupBy(data.deliverySchedules, (item) => item.week)
    );
    const scheduleKey = Object.keys(delSched);
    scheduleKey.forEach((element) => {
      delSched[element].forEach((el) => {
        if (!el.consigned) {
          if (!el.cancellationReason) {
            schedules.push(el);
          } else {
            cancelledSchedules.push(el);
          }
        }
      });
    });
    this.scheduleToShip = _.mapValues(
      _.groupBy(schedules, (item) => item.week)
    );
    this.cancelledScheduleToShip = _.mapValues(
      _.groupBy(cancelledSchedules, (item) => item.week)
    );
    this.resolveChartLabelsAndSetChartColor();
    this.fillProductsArray();
    this.ref.detectChanges();
  }

  checkForDuplicates(source, keyName) {
    return (
      source.filter((item, index, array) => {
        return array.findIndex((t) => t[keyName] === item[keyName]) === index;
      }).length !== source.length
    );
  }

  fillProductsArray() {
    const entries: any[] = this.orderHistory[0]?.entries;
    for (let i = 0; i < entries?.length; i++) {
      const chartObj = {
        productName: entries[i]?.product?.name,
        doses: entries[i]?.quantity,
      };
      this.chartTableArray.push(chartObj);
      this.productsArray.push(entries[i]?.product);
    }
  }

  resolveChartLabelsAndSetChartColor() {
    if (
      this.totalShippedQuantity !== undefined &&
      this.totalShippedQuantity !== 0
    ) {
      this.doughnutChartLabels.push(
        'Expédiée ' +
          Math.round((this.totalShippedQuantity / this.totalDoses) * 100) +
          '%'
      );
      this.newDoughnutLabel.push(
        'Expédiée ' +
          Math.round((this.totalShippedQuantity / this.totalDoses) * 100) +
          '%'
      );

      this.chartsBgColorArray.push('#34A760');
      this.doughnutChartData.push(this.totalShippedQuantity);
      this.newDoughnutData.push(this.totalShippedQuantity);
      this.dosesInChartDisplay -= this.totalShippedQuantity;
    }

    if (
      this.totalWaitingQuantity !== undefined &&
      this.totalWaitingQuantity !== 0
    ) {
      this.doughnutChartLabels.push(
        this.QUANTITY_STATUS.IN_PREP +
          Math.round((this.totalWaitingQuantity / this.totalDoses) * 100) +
          '%'
      );
      this.newDoughnutLabel.push(
        this.QUANTITY_STATUS.IN_PREP +
          Math.round((this.totalWaitingQuantity / this.totalDoses) * 100) +
          '%'
      );
      this.chartsBgColorArray.push('#0A78CD');
      this.doughnutChartData.push(this.totalWaitingQuantity);
      this.newDoughnutData.push(this.totalWaitingQuantity);
      this.dosesInChartDisplay -= this.totalWaitingQuantity;
    }
    if (
      this.totalDoses !== undefined &&
      this.totalDoses !== 0 &&
      this.dosesInChartDisplay > 0
    ) {
      this.doughnutChartLabels.push(
        this.EN_ATTENTE +
          Math.round((this.dosesInChartDisplay / this.totalDoses) * 100) +
          '%'
      );
      this.newDoughnutLabel.push(
        this.EN_ATTENTE +
          Math.round((this.dosesInChartDisplay / this.totalDoses) * 100) +
          '%'
      );
      this.chartsBgColorArray.push('#666');
      this.doughnutChartData.push(this.dosesInChartDisplay);
      this.newDoughnutData.push(this.dosesInChartDisplay);
    }
    this.chartColors = [
      {
        backgroundColor: this.chartsBgColorArray,
      },
    ];
    this.doughnutChartDatasets = [
      {
        backgroundColor: this.chartsBgColorArray,
        data: this.newDoughnutData,
        borderWidth: 1,
      },
    ];
    this.doughnutChartLabelsNew = this.newDoughnutLabel;
  }

  trackingProduct(url) {
    window.open(url, '_blank');
  }
}
