import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, OccEndpointsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { AccountType } from "../../custom-user-registration/registration.constants";
import { CustomInfluenzaService } from '../custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';
@Component({
  selector: 'app-influenza-dashbaord-footer',
  templateUrl: './influenza-dashbaord-footer.component.html',
  styleUrls: ['./influenza-dashbaord-footer.component.scss']
})
export class InfluenzaDashbaordFooterComponent implements OnInit,OnDestroy {
  userData: any = [];
  contactAddress: any;
  activeSeasons: any;
  productList = [];
  infContact: any = [];
  editNotClicked = true;
  saveClicked = false;
  @Input() reserveNowClicked: any;
  @Input() displayActiveSeasons:any;
  @Input() dispalayFooterSeason: any;
  @Output() newItemEvent = new EventEmitter<string>();
  emailPattern = '^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  faxPattern = '^[0-9]{3}-[0-9]{3}-[0-9]{4}$';
  compName = 'SanofiFluDashboardBannerComponent';
  editForm = new FormGroup({
    firstName: new FormControl('', [Validators.required,
    Validators.pattern("^[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$")]),
    lastName: new FormControl('', [Validators.required,
    Validators.pattern("^[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$")]),
    emailId: new FormControl('', [
    Validators.pattern('^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),

    faxNo: new FormControl('', Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')),
    language: new FormControl(''),
    preferredContact: new FormControl('')
  });

  languages = [];
  chosenLang = '';
  preferredContact = '';
  baseUrl: any;
  emailChecked: boolean;
  faxChecked: boolean;
  fnameEdited: boolean;
  fname: any;
  screenwidth: any;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  addClicked: boolean;
  languageUsed = 'en';
  preferredLang: string;
  activeSeasonSubscriber: any;
  influenzaConatctSubscriber: any;
  updateConatctSubscirber: any;
  hospital: boolean;
  endDate: any;
  todayDate: Date;
  startDate: any;
  constructor(
    private readonly influenzaService: CustomInfluenzaService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly occEndPoint: OccEndpointsService, private readonly router: RoutingService,
    private readonly modalService: NgbModal,
    private readonly lang: LanguageService
  ) {
    this.screenwidth = window.screen.width;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
  }

  ngOnInit(): void {
    this.hospital = sessionStorage.getItem('accountType') === AccountType.Hospital;
    this.lang.getActive().subscribe((res)=>{
      this.languages=[];
      this.languageUsed = res;
      if(this.languageUsed ==='en'){
        this.languages = ['English','French'];
      } else{
        this.languages = ['Anglais','Français'];
      }
    });
    this.user.get().subscribe((res) => {
      if (res) {
        this.userData = res;
        this.influenzaService.getActiveSeasonsFromDb(this.userData.userName);
        this.influenzaService.getFluOrderContact(this.userData.userName);
        this.influenzaService.getB2bUnitForUser(this.userData.userName);
        this.getActiveSeasons();
        this.getInfContact();
        this.getB2bContact();
      }
    });
  }
  ngOnDestroy(){
    this.activeSeasonSubscriber?.unsubscribe();
    this.influenzaConatctSubscriber?.unsubscribe();
    this.updateConatctSubscirber?.unsubscribe();
  }
  getActiveSeasons() {
    this.activeSeasonSubscriber=this.influenzaService.activeSeaons?.subscribe((data) => {
      if (data) {
        this.productList = data?.categoryData[0]?.products;
        if (this.productList) {
          this.productList.forEach((ele) => {
            let thumbNailImg = '';
            if (ele?.images) {
              thumbNailImg = ele?.images[2]?.url;
            }
            ele['thumbNailImg'] = thumbNailImg;
          });
          this.activeSeasons = data.categoryData[0].code;
          if (this.activeSeasons){
            this.activeSeasons = this.activeSeasons.replace('-', '/');
          }
          
          this.todayDate = new Date();
          this.endDate = data.categoryData[0]?.endDate;
          this.startDate = data.categoryData[0]?.startDate;
          if (
            this.todayDate > new Date(this.endDate) ||
            this.todayDate < new Date(this.startDate) ||
            !data.categoryData?.[0]
          ) {
            this.dispalayFooterSeason = false;
          }
        }

        this.ref.detectChanges();
      }
      this.activeSeasonSubscriber?.unsubscribe();

    });
  }
  getB2bContact(){
    this.contactAddress = this.influenzaService.b2bUnit.subscribe(resp => {
        if (resp) {
          this.contactAddress = resp?.defaultB2BUnit?.contactAddress;
          this.infContact = resp?.defaultB2BUnit?.influenzaContactInfos;
          if (this.infContact) {
            this.newItemEvent.emit(this.infContact[0].firstName);
            this.ref.detectChanges();
          }

        }
        this.influenzaConatctSubscriber?.unsubscribe();
      });
  }
  getInfContact() {
    this.influenzaConatctSubscriber=this.influenzaService.influenzaContact.subscribe(resp => {
      if (resp) {
        this.infContact = resp?.defaultB2BUnit?.influenzaContactInfos;
        if (this.infContact) {
          this.newItemEvent.emit(this.infContact[0].firstName);
          this.ref.detectChanges();
        }

      }
      this.influenzaConatctSubscriber?.unsubscribe();

    });
  }
  editContact() {

    this.editNotClicked = false;
    if (this.infContact) {
      this.editForm.patchValue({
        firstName: this.infContact[0].firstName,
        lastName: this.infContact[0].lastName,
        emailId: this.infContact[0].email,
        faxNo: this.infContact[0].fax,
        language: this.infContact[0].preferredLanguage === 'EN' ? this.setEnglish() : this.setFrench(),
        preferredContact: this.infContact[0].contactMethod
      });
      this.chosenLang = this.infContact[0].preferredLanguage === 'EN' ? this.setEnglish() : this.setFrench();

    } else {
      this.chosenLang = this.setEnglish();
      this.editForm.controls['preferredContact'].setValue('INT');
    }

    this.formValidations();
  }
  setEnglish() {
    if(this.languageUsed === 'en'){
      this.preferredLang = 'English';
    } else{
      this.preferredLang = 'Anglais';
    }
    return this.preferredLang;
  }
  setFrench() {
    if(this.languageUsed === 'en'){
      this.preferredLang = 'French';
    } else{
      this.preferredLang = 'Français';
    }
    return this.preferredLang;
  }
  onCancelClick() {

    this.editNotClicked = true;
    this.editForm.reset();
  }
  onSave() {
    this.saveClicked = true;
    const params = {
      email: this.editForm.controls.emailId.value ? this.editForm.controls.emailId.value : '',
      firstName: this.editForm.controls.firstName.value,
      lastName: this.editForm.controls.lastName.value ? this.editForm.controls.lastName.value : '',
      phoneNo: this.infContact ? this.infContact[0]?.phoneNo : '',
      contactMethod: this.editForm.controls.preferredContact.value,
      fax: this.editForm.controls.faxNo.value ? this.editForm.controls.faxNo.value : '',
      fluContactId: this.infContact ? this.infContact[0]?.fluContactId : '',
      preferredLanguage: this.chosenLang === this.setEnglish() ? 'EN' : 'FR'
    };
    this.formValidations();
    if (!this.editForm.invalid) {
      this.influenzaService.updateContact(params, this.userData.userName);
     this.updateConatctSubscirber= this.influenzaService.updatedInfContact.subscribe((result) => {
        if (result) {
          this.infContact = [];
          this.infContact[0] = result;
        }
        this.updateConatctSubscirber?.unsubscribe();
        this.ref.detectChanges();
      });
      this.editNotClicked = true;
    } else {
      this.editForm?.markAllAsTouched();
    }

  }
  onReserve() {
    sessionStorage.setItem('isAmendmentCart', 'false');
    if (this.contactAddress) {
      if (this.contactAddress?.firstName) {
        this.router.goByUrl('/influenza/category');
      }
    } else {
      this.reserveNowClicked = true;
    }
  }
  productDetails(productDetails) {
    const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent, { windowClass: 'product-details-inf-modal' });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = productDetails.code;
  }
  formValidations() {
    if (this.editForm.controls.preferredContact.value === 'INT') {
      this.editForm.controls.emailId.setValidators([Validators.required,
      Validators.pattern(this.emailPattern)]);
      this.editForm.controls.emailId.updateValueAndValidity();
      this.editForm.controls.faxNo.clearValidators();
      this.editForm.controls.faxNo.setValidators([Validators.pattern(this.faxPattern)]);
      this.editForm.controls.faxNo.updateValueAndValidity();
    }
    if (this.editForm.controls.preferredContact.value === 'FAX') {
      this.editForm.controls.faxNo.setValidators([Validators.required, Validators.pattern(this.faxPattern)]);
      this.editForm.controls.faxNo.updateValueAndValidity();
      this.editForm.controls.emailId.clearValidators();
      this.editForm.controls.emailId.setValidators([
      Validators.pattern(this.emailPattern)]);
      this.editForm.controls.emailId.updateValueAndValidity();
    }
  }
  onChange(event) {
   this.formValidations();
  }

}
