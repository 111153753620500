import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpErrorHandler,
  HttpResponseStatus,
  GlobalMessageType,
  Priority,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class ForbiddenHandler extends HttpErrorHandler {
  getPriority?(): Priority;

  responseStatus = HttpResponseStatus.BAD_REQUEST;

  handleError(request: HttpRequest<any>, errorResponse: HttpErrorResponse) {
    if(request.url.indexOf('assisted-service/customers/search') === -1)  { // Don't show error if asm user not valid
      if (errorResponse.error.hasOwnProperty('errors')) {
        this.globalMessageService.add(
          errorResponse.error.errors[0].message,
          GlobalMessageType.MSG_TYPE_ERROR
        );
      } else {
        this.globalMessageService.add(
          errorResponse.error.error_description,
          GlobalMessageType.MSG_TYPE_ERROR
        );
      }
    }
  }
}
