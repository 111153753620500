import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 
@Component({
  selector: 'app-externalsite-alert',
  templateUrl: './externalsite-alert.component.html'
})
export class ExternalsiteAlertComponent {
   
  @Input() public eventReference;
  constructor(
    private readonly modalService: NgbModal
  ) { }

  redirectto(){
    window.open(this.eventReference);
    this.modalService.dismissAll();
  }
  dismissModal(){
    this.modalService.dismissAll();
  }
}