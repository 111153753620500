import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService } from '@spartacus/core';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import {AccountType} from "../../custom-user-registration/registration.constants";
@Component({
  selector: 'app-product-details-influenza',
  templateUrl: './product-details-influenza.component.html',
  styleUrls: ['./product-details-influenza.component.scss'],
})
export class ProductDetailsInfluenzaComponent implements OnInit {
  @Input() public productDetails;
  @Input() public standardCartCode;
  baseUrl: any;
  monograph: any;
  monographLink: any;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  hospital: boolean;
  constructor(
    private readonly modalService: NgbModal,
    private readonly occEndPoint: OccEndpointsService,
    private _dataLayerService: DataLayerService
  ) {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
  }

  ngOnInit(): void {
    this.hospital = sessionStorage.getItem('accountType') === AccountType.Hospital;
    this.monograph = this.productDetails?.productDocuments;
    if (this.productDetails) {
      this._dataLayerService.viewItemDataLayer(
        this.productDetails,
        'new reservation'
      );
    }
    if (this.monograph) {
      for (const link of this.monograph?.productDocument) {
        this.monographLink = link.url;
      }
    }
  }
  close(): void {
    this.modalService.dismissAll();
  }
}
