import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CmsService, LanguageService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/services/base.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CustomInfluenzaService } from '../custom-influenza.service';

@Component({
  selector: 'app-influenza-dashboard',
  templateUrl: './influenza-dashboard.component.html',
  styleUrls: ['./influenza-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InfluenzaDashboardComponent implements OnInit, OnDestroy {
  userData: any = [];
  idofReservation: any;
  idofPreviousReservation: any;
  orderHistory: any = ['1'];
  previousHistoryFlag: boolean;
  previousSeasonOrderHistory: any = ['1'];
  chosenReservation: any;
  chosenPreviousReservation: any;
  reserveNowClicked = false;
  statusDisplay: String = '';
  previousStatusDisplay: any;
  infContact: any;
  activeSeasons: any;
  maxOrderPercentage: any;
  activeSeasonsToShow: any;
  previousSeason: any;
  cmsResponse: any;
  fluParagraph: any;
  endDate: any;
  todayDate: Date;
  displayActiveSeasons = true;
  displayPreviousSeason = true;
  dispalayFooterSeason = true;
  displayWholesalerMessage = true;
  startDate: any;
  wholesalerMessage: string;
  orderHistorySubscriber: any;
  orderPreviousSeasonHistorySubscriber: any;
  activeSeasonsSubscriber: any;
  previousSeaonsSubscriber: any;
  languageUsed: string;
  contactAddress: any;
  seasonOrders: any = [];
  orderPlaced: boolean = false;
  seasonDisplay: String = '';
  editButtonConfig: Observable<any>;
  showButton: boolean = false;;
  constructor(
    private readonly cmsData: CmsService,
    private readonly influenzaService: CustomInfluenzaService,
    private readonly user: UserAccountFacade,
    private readonly renderer: Renderer2,
    private readonly ref: ChangeDetectorRef,
    private readonly router: RoutingService,
    private readonly sharedService: SharedService,
    private readonly languageService: LanguageService,
    private readonly baseService: BaseService,
    private readonly navigateRouter: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.languageService.getActive().subscribe((langRes) => {
      this.languageUsed = langRes;
      this.ref.detectChanges();
    });
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.classList.contains('close-banner')) {
        const displayInflunenzaBanner = document.getElementsByClassName(
          'FluParaMiddleSlottop'
        );
        for (let i = 0; i < displayInflunenzaBanner.length; i++) {
          displayInflunenzaBanner[i].className += ' displayNone';
        }
      }
    });
    this.getB2bContact();

    this.user.get().subscribe((res: any) => {
      if (res) {
        this.userData = res;
      }
    });
    // Active Season
    this.activeSeasonsSubscriber = this.influenzaService.activeSeaons.subscribe(
      (data) => {
        if (data) {
          this.activeSeasons = data.categoryData?.[0].code;
          this.maxOrderPercentage = data.categoryData?.[0].goodiesProductsMaxQuantityPercentage;
          this.activeSeasonsToShow = data.categoryData?.[0].code;
          this.influenzaService.getUserOrderHistory(
            this.userData.userName,
            this.activeSeasons
          );
          if (this.activeSeasons) {
            this.activeSeasons = this.activeSeasons.replace('-', '/');
          }
          this.sharedService.setSessionStorage(
            'activeSeasons',
            this.activeSeasons
          );
          this.sharedService.setSessionStorage(
            'maxOrderPercentage',
            this.maxOrderPercentage
          );
          this.todayDate = new Date();
          this.endDate = data.categoryData[0]?.endDate;
          this.startDate = data.categoryData[0]?.startDate;
          if (
            this.todayDate > new Date(this.endDate) ||
            this.todayDate < new Date(this.startDate) ||
            !data.categoryData?.[0]
          ) {
            this.dispalayFooterSeason = false;
          }
          if (
            this.todayDate <
              new Date(data.categoryData[0]?.fluCampaignEndDate) &&
            this.todayDate >
              new Date(data.categoryData[0]?.fluCampaignStartDate)
          ) {
            this.wholesalerMessage = data.categoryData[0]?.wholesalerMessage;
          } else if (
            this.todayDate <
              new Date(data.categoryData[1]?.fluCampaignEndDate) &&
            this.todayDate >
              new Date(data.categoryData[1]?.fluCampaignStartDate)
          ) {
            this.wholesalerMessage = data.categoryData[1]?.wholesalerMessage;
          } else {
            this.displayWholesalerMessage = false;
          }
          if (!data.categoryData?.[0]) {
            this.displayActiveSeasons = false;
            this.ref.detectChanges();
          }

          //Flu Season Orders
          const seasons = data.categoryData;
          seasons.forEach((element) => {
            this.baseService
              .get(
                'users/' +
                  this.userData.userName +
                  '/orders?currentPage=0&fields=DEFAULT&orderType=ZPRE&seasonId=' +
                  element.code
              )
              .subscribe((res) => {
                if (res) {
                  const order = {
                    seasonId: element.code,
                    orders: res,
                  };
                  this.seasonOrders.push(order);
                  this.ref.detectChanges();
                }
              });
          });
        }
        this.activeSeasonsSubscriber?.unsubscribe();
      }
    );
    // Individual order active
    this.orderHistorySubscriber = this.influenzaService.ordersHistory.subscribe(
      (orderData) => {
        if (orderData) {
          this.orderHistory = orderData?.orders;
          if (this.orderHistory.length > 0) {
            this.orderPlaced = true;
          } else {
            this.orderPlaced = false;
          }
          this.chosenReservation = this.orderHistory?.[0]?.guid;
          this.idofReservation = this.chosenReservation;
          this.statusDisplay = this.orderHistory?.[0]?.statusDisplay;
          this.seasonDisplay = this.activeSeasons;

          if (this.orderHistory?.length >= 1) {
            this.dispalayFooterSeason = false;
          }
          if (this.idofReservation) {
            this.influenzaService.getDetailedReservation(
              this.userData.userName,
              this.idofReservation,
              false,
              true
            );
          }
          this.fluEditButtonConfig();
          this.ref.detectChanges();
          this.checkDateRange();
        }
        this.orderHistorySubscriber?.unsubscribe();
      }
    );
    //Previous season
    this.previousSeaonsSubscriber =
      this.influenzaService.previousSeasons.subscribe((data) => {
        if (data) {
          const season1 = data.categoryData?.[1];
          const season2 = data.categoryData?.[2];
          if (season1) {
            this.previousSeason = season1.code;
            this.influenzaService.getUserPreviousSeasonOrderHistoryOther(
              this.userData.userName,
              this.previousSeason
            );
            this.sharedService.setSessionStorage(
              'previousSeasons',
              [season1, season2]
            );
            this.todayDate = new Date();
            this.endDate = data.endDate;
            this.startDate = data.startDate;
          }
        }
        this.previousSeaonsSubscriber?.unsubscribe();
      });

    this.getFluParagraph();
    this.ref.detectChanges();
  }
  ngOnDestroy() {
    this.activeSeasonsSubscriber?.unsubscribe();
    this.orderHistorySubscriber?.unsubscribe();
    this.previousSeaonsSubscriber?.unsubscribe();
    this.orderPreviousSeasonHistorySubscriber?.unsubscribe();
  }
  changeReservation(data, flag, seasonYear) {
    this.previousHistoryFlag = flag;
    const season = seasonYear.toString(2).replace(/\-/g, '/');
    if (data !== null) {
      this.idofReservation = data.guid;
      this.statusDisplay = data.statusDisplay;
      this.seasonDisplay = season;
    } else {
      this.idofReservation = this.chosenReservation;
    }
    this.influenzaService.getDetailedReservation(
      this.userData.userName,
      this.idofReservation,
      false,
      true
    );
    this.fluEditButtonConfig();
    this.ref.detectChanges();
    this.checkDateRange();
  }
  viewReservation() {
    this.influenzaService.getDetailedReservation(
      this.userData.userName,
      this.idofReservation,
      true,
      false
    );
  }
  proceedToCategory(event) {
    this.infContact = event;
  }
  reserveNow() {
    sessionStorage.setItem('isAmendmentCart', 'false');
    if (this.contactAddress) {
      this.router.goByUrl('/influenza/category');
    } else {
      this.reserveNowClicked = true;
    }
  }
  getFluParagraph() {
    this.cmsData.getComponentData('FluMessageParagraph').subscribe((res) => {
      this.cmsResponse = res;
      if (this.cmsResponse && this.cmsResponse.content) {
        this.fluParagraph = this.cmsResponse.content;
      }
    });
  }
  getB2bContact() {
    this.contactAddress = this.influenzaService.b2bUnit.subscribe((resp) => {
      if (resp) {
        this.contactAddress = resp?.defaultB2BUnit?.contactAddress;
        this.ref.detectChanges();
      }
    });
  }

  fluEditReserveUpdate(totalMinDosesThreshold: number) {
    this.user.get().subscribe((userData) => {
      const userId = userData.uid;

      if (userId) {
        this.influenzaService.getFluPreOrderUpdate(userId, this.idofReservation).subscribe(
          (response) => {
            if (response) {
              this.navigateRouter.navigate(['/influenza/category'], {
                state: {
                  editReservefluData: response,
                  minThreshold: totalMinDosesThreshold,
                },
              });
            }
          },
          (error) => {
            console.error('Error during flu pre-order update:', error);
          }
        );
        this.influenzaService.setInfluenzaOrderId(this.idofReservation);
        this.influenzaService.setFluReserve(null);
        this.influenzaService.setInfluenzaThreshold(null);
        sessionStorage.setItem('isAmendmentCart', 'true');
      }
    });
  }

  fluEditButtonConfig(){
    this.user.get().subscribe((userData) => {
      this.editButtonConfig = this.influenzaService.getEditFluPreOrderConfig(userData?.uid, this.idofReservation);
    });
  }

  checkDateRange(): void {
    this.editButtonConfig.subscribe((data) => {
      const currentDate = new Date();
      const fromDate = data?.editFrom ? new Date(data?.editFrom) : null;
      const toDate = data?.editTo ? new Date(data?.editTo) : null;
      if (fromDate && toDate && currentDate >= fromDate && currentDate <= toDate) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
      this.ref.detectChanges();
    });
  }
}
