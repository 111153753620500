import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import {
  HamburgerMenuService,
  NavigationNode,
  NavigationUIComponent,
} from '@spartacus/storefront';

@Component({
  selector: 'cx-navigation-ui',
  templateUrl: './custom-navigation-ui.component.html'
})
export class CustomNavigationUiComponent extends NavigationUIComponent {
  constructor(
    router: Router,
    renderer: Renderer2,
    elemRef: ElementRef,
    protected childRenderer: Renderer2,
    protected childElemRef: ElementRef,
    protected hamburgerMenuService: HamburgerMenuService,
    protected winRef: WindowRef
  ) {
    super(router, renderer, elemRef, hamburgerMenuService, winRef);
  }

  closeIfClickedTheSameLink(navNode: NavigationNode): void {
    if (
      typeof navNode.url === 'string' &&
      this.winRef.nativeWindow?.location.href.includes(navNode.url)
    ) {
      this.childElemRef.nativeElement
        .querySelectorAll('li.is-open:not(.back), li.is-opened')
        .forEach((el: any) => {
          this.childRenderer.removeClass(el, 'is-open');
          this.childRenderer.removeClass(el, 'is-opened');
        });
      this.reinitializeMenu();
      this.hamburgerMenuService.toggle(true);
    }
  }
}
