import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbModal,
  NgbPopover,
  NgbPopoverConfig,
} from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  LanguageService,
  OccEndpointsService,
  RoutingService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import * as _ from 'underscore';
import { CustomAddToCartService } from '../../custom-add-to-cart/custom-add-to-cart.service';
import { ProductDetailsComponent } from '../../custom-add-to-cart/custom-add-to-cart/product-details/product-details.component';
import { CustomInfluenzaService } from '../../custom-influenza/custom-influenza.service';
import { AccountType } from '../../custom-user-registration/registration.constants';
import { ExcelDownloadService } from '../../shared/services/excel-download.service';
import { OrderHistoryService } from '../order-history.service';
declare const require: any;

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  currentPopover: NgbPopover;

  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  orderDetailsData: any;
  userData: any;
  orderCode: any;
  baseUrl: string;
  saveCartName: string;
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  modalReference: any;
  cartId: string;
  childCartID: any;
  standardCartDetails: any;
  errorMsg: string;
  cartItems: any;
  cartAllItems: any;
  userSubscription: Subscription;
  orderCartSubscription: Subscription;
  orderDetailSubscription: Subscription;
  addToCartSubscription: Subscription;
  userAccountSubscription: Subscription;
  userDetailSubscription: Subscription;
  saveOrderSubscription: Subscription;
  invalidOrderSubscription: Subscription;
  createCartSubscription: Subscription;
  addCartDetailsSubscription: Subscription;
  deleteOrderSubscription: Subscription;
  currentLangSub: Subscription;
  currentLang: String;
  productID: any;
  qty: any;
  productName: any;
  billToLocation: any;
  shipToLocation: any;
  excelCLicked: boolean = false;
  excelName: any;
  placedByAsm: any;
  activeOrderProducts: boolean = true;
  hospital: boolean;
  orderDeliveryNotes: any[] = [];
  deliveryNotesSub: any;
  showDisclaimer: boolean = true;
  isIndirectOrder = false;
  cancelledEntries: any[] = [];
  activeSeasonSubscriber: Subscription;
  isFluPreOrderSeasonOpen: boolean = false;
  startDate: Date;
  endDate: Date;
  todayDate: Date = new Date();
  editButtonConfig: Observable<any>;
  showButton: boolean = false;
  cartIdSubscriber: any;
  cartSubscriber: any;
  activeCartId = '';
  userId = '';
  fluOrderData: any;
  reservationId: any = '';
  minThresholdValue: number;
  orderEntry: any;

  constructor(
    private readonly modalService: NgbModal,
    private readonly user: UserAccountFacade,
    private readonly orderService: OrderHistoryService,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private readonly occEndPoint: OccEndpointsService,
    private readonly addToCart: CustomAddToCartService,
    private readonly router: Router,
    public excelService: ExcelDownloadService,
    private readonly config: NgbPopoverConfig,
    private readonly sanitizer: DomSanitizer,
    private languageService: LanguageService,
    private readonly routingService: RoutingService,
    private authService: AuthService,
    private dataLayerService: DataLayerService,
    private readonly influenzaService: CustomInfluenzaService
  ) {
    config.autoClose = 'outside';
  }

  ngOnInit(): void {
    this.hospital =
      sessionStorage.getItem('accountType') === AccountType.Hospital;
    this.minThresholdValue = history.state.minThreshold;

    const url = this.router.url;
    const orderId = url.match(/[^\/]+$/)[0];
    this.orderCode = orderId;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.userSubscription = this.user.get().subscribe((userResData) => {
      const userRes: any = userResData;
      if (userResData) {
        this.userData = userRes;
        this.orderService.getOrderDetails(this.userData.userName, orderId);
        this.getUserAccountData(this.userData.userName);
        if (this.userData && this.userData !== null) {
          this.orderService.getCarts(this.userData?.userName);
        }
        this.influenzaService.getActiveSeasonsFromDb(this.userData?.userName);
        this.activeSeasonSubscriber =
          this.influenzaService.activeSeaons.subscribe((data) => {
            this.todayDate = new Date();
            this.startDate = new Date(data.categoryData[0]?.startDate);
            this.endDate = new Date(data.categoryData[0]?.endDate);
            this.isFluPreOrderSeasonOpen = this.checkFluPreOrderSeason();
            sessionStorage.setItem(
              'activeFluSeason',
              String(this.isFluPreOrderSeasonOpen)
            );
            this.cd.detectChanges();
          });
      }
    });
    this.orderCartSubscription = this.orderService.orderCartItems$.subscribe(
      (res) => {
        if (res) {
          this.cartAllItems = res?.carts;
          this.cartAllItems = this.cartAllItems.filter(
            (item) => item?.saveTime === undefined
          );
          this.cartAllItems = this.cartAllItems[this.cartAllItems?.length - 1];
          this.cartId = this.cartAllItems?.code;
          if (this.cartId !== undefined) {
            this.cartChecking();
          } else {
            this.standardCartDetails = [];
          }
        }
      }
    );

    this.orderDetailSubscription = this.orderService.orderDetails$.subscribe(
      (res) => {
        if (res !== false && res !== null && res.length !== 0) {
          this.orderDetailsData = res;
          this.reservationId = this.orderDetailsData?.code;
          this.orderDetailsData.entries = _.uniq(
            this.orderDetailsData.entries,
            (entry) => {
              return entry.product.code;
            }
          );
          const createDate = this.orderDetailsData.created.split('T');
          this.orderDetailsData.created = createDate[0];
          this.orderDetailsData.entries.forEach((element) => {
            const imagesFormat = _.clone(
              _.findWhere(element.product?.images, { format: 'thumbnail' })
            );
            if (imagesFormat) {
              imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
              element.PRIMARY = imagesFormat;
            }
            this.placedByAsm = this.orderDetailsData.placedBy;
            element.prodConsignments = [];
            element.unconsignedEntries = [];
            let statusDate;
            this.orderDetailsData.consignments.forEach((consignment) => {
              if (consignment.entries.length > 0) {
                if (
                  consignment.statusDate
                ) {
                  statusDate = consignment.statusDate;
                }
                consignment.entries.forEach((entry) => {
                  if (entry.orderEntry.product.code === element.product.code) {
                    if (entry.length !== 0) {
                      if (entry.orderEntry.quantityStatus !== 'Annulé') {
                        entry.statusDate = statusDate;
                        element.prodConsignments.push(entry);
                      } else {
                        this.cancelledEntries.push(entry.orderEntry);
                      }
                    }
                  }
                });
              }
            });

            this.orderDetailsData.unconsignedEntries.forEach((entry) => {
              if (entry.product.code === element.product.code) {
                const existingConsignment = element.prodConsignments.find(
                  (consignment) => consignment.orderEntry.entryNumber === entry.entryNumber
                );
                if (!existingConsignment) {
                  if (entry.quantityStatus !== 'Annulé') {
                    element.unconsignedEntries.push(entry);
                  } else {
                    this.cancelledEntries.push(entry);
                  }
                }
              }
            });

            this.cancelledEntries = this.cancelledEntries.filter(
              (obj, index, self) =>
                index ===
                self.findIndex((o) => o.entryNumber === obj.entryNumber)
            );

            this.activeOrderProducts = this.orderDetailsData.entries.every(
              (item) => item.product.displayProduct
            );
          });
          this.fluEditButtonConfig(this.orderDetailsData);
          this.checkDateRange(this.orderDetailsData);
          this.cd.detectChanges();
        }
      }
    );
    this.cd.detectChanges();
  }

  checkFluPreOrderSeason(): boolean {
    return this.todayDate >= this.startDate && this.todayDate <= this.endDate;
  }

  shouldDisplayButton(
    cartType: string,
    isPreOrderSeasonOpen: boolean,
    activeOrderProducts: any[]
  ): boolean {
    // If no active products, don't display the button
    if (!activeOrderProducts) {
      return false;
    }

    // For non-hospital users
    if (!this.hospital) {
      if (cartType === 'INFLUENZA') {
        return false; // Hide for influenza orders
      }
      return true; // Show for standard or indirect orders
    }

    // For hospital users
    if (this.hospital) {
      if (cartType === 'INFLUENZA') {
        return false; // Hide for influenza orders
      }

      if (cartType === 'STANDARD' || cartType === 'INDIRECT') {
        const hasFluProducts = this.isFlu(activeOrderProducts);
        // Hide if flu season is open and there are flu products
        if (isPreOrderSeasonOpen && hasFluProducts) {
          return false;
        }
        return true; // Show buttons if no flu products or flu season is closed
      }
    }
    return true;
  }

  // Method to check if there are flu products in the order entries
  isFlu(orderEntries: any[]): boolean {
    return orderEntries.some((entry) => entry.product && entry.product.flu);
  }

  loadDeliveryNotes(orderCode: string) {
    this.orderDeliveryNotes = [];
    const params = {
      baseSiteId: 'fr',
      userId: this.userData.userName,
      orderCode: orderCode,
    };
    this.orderService.getDeliveryNotes(params);

    this.deliveryNotesSub = this.orderService.deliveryNotes$.subscribe(
      (res) => {
        if (res) {
          this.orderDeliveryNotes = res.deliveryNotes;
        }
      }
    );
  }

  get showQuantityMessage(): boolean {
    return this.orderDetailsData?.entries?.some((entry) => {
      const requestedQuantity = Number(entry?.quantity);

      // Check if any confirmedQuantity in either array is less than the requested quantity
      const checkQuantity = (array) =>
        array?.some((data) => {
          const confirmedQuantity = data?.confirmedQuantity
            ? Number(data.confirmedQuantity)
            : 0;
          return confirmedQuantity > 0 && confirmedQuantity < requestedQuantity;
        });

      return (
        checkQuantity(entry.unconsignedEntries) ||
        checkQuantity(entry.prodConsignments)
      );
    });
  }

  downloadDeliveryNotePdf(dNoteUrl: string) {
    const paramStr = dNoteUrl.substring(dNoteUrl.indexOf('?') + 1);
    const params = {
      baseSiteId: 'fr',
      userId: this.userData.userName,
      paramStr: paramStr,
    };
    this.orderService.downloadDeliveryNotePdf(params);
  }

  closeDisclaimerAlert() {
    this.showDisclaimer = false;
  }
  cartChecking() {
    this.addToCart.getCarts(this.cartId, this.userData?.userName);
    this.addToCartSubscription = this.addToCart.cartItems$.subscribe(
      (cartData: any) => {
        if (cartData) {
          let stdCartDetails = [];
          stdCartDetails = cartData?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === this.orderDetailsData.cartType
            );
            if (stdCartDetails?.length !== 0) {
              this.standardCartDetails = Object.assign({}, ...stdCartDetails);
            }
          }
        }
      }
    );
  }
  getUserAccountData(userId): void {
    this.addToCart.getUserAccountDataFromDb(userId);
    this.userAccountSubscription = this.addToCart.userAccountData.subscribe(
      (userData) => {
        if (
          userData?.res?.length !== 0
        ) {
          this.usergetDataObj = userData.res.defaultB2BUnit;
          const accountId = this.usergetDataObj.uid;
          this.addToCart.getUserAccountDetailsDataFromDb(userId, accountId);
          this.userDetailSubscription =
            this.addToCart.userAccountDetails.subscribe((userDataget) => {
              if (userDataget) {
                this.getuserData = userDataget.res.shippingAddresses;
                this.searchedClientList = userDataget?.res?.shippingAddresses;
                this.selectedData = this.getuserData[0];
                this.saveCartName = this.selectedData?.companyName;
                this.cd.detectChanges();
              }
            });
        }
      }
    );
  }
  openProductDetails(productDetails) {
    if (productDetails.basePrice.formattedValue !== undefined) {
      productDetails.product.price = {
        value: productDetails.basePrice.formattedValue,
      };
    }
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = '';
    modalRef.componentInstance.unitSection = 'NoUnits';
  }
  openModal(content) {
    if (
      this.standardCartDetails !== undefined &&
      this.standardCartDetails?.length !== 0
    ) {
      this.modalReference = this.modalService.open(content, {
        size: 'md',
        windowClass: 'modal-md',
      });
    } else {
      this.getCartId('addCart');
    }
  }
  toggle(popover) {
    this.currentPopover = popover;
    if (popover.isOpen()) {
      popover.close();
      this.errorMsg = '';
    } else {
      popover.open();
    }
  }
  existingCart() {
    this.modalReference.close();
  }
  excelDownload() {
    this.currentLangSub = this.languageService.getActive().subscribe((res) => {
      this.currentLang = res;
    });
    this.excelCLicked = true;
    const excelArray = [];

    if (this.currentLang && this.currentLang !== undefined) {
      switch (this.currentLang) {
        case 'en':
          this.productID = 'CIP Code';
          this.productName = 'Product Name';
          this.qty = 'Units';
          this.shipToLocation = 'Ship To Location';
          this.billToLocation = 'Bill To Location';
          this.excelName = 'Order Details';
          break;
        default:
          this.productID = 'CIP Code';
          this.productName = 'Nom du produit';
          this.qty = 'Unités';
          this.shipToLocation = 'Adresse de livraison';
          this.billToLocation = 'Adresse de facturation';
          this.excelName = 'Page des détails de la commande';
          break;
      }
    }

    this.orderDetailsData.entries.forEach((element) => {
      const obj = {
        [this.productID]: element.product.code,
        [this.productName]: element.product.name,
        [this.qty]: `${element.quantity}`,
        [this.shipToLocation]:
          this.orderDetailsData.deliveryAddress.formattedAddress,
        [this.billToLocation]:
          this.orderDetailsData.billingAddress.formattedAddress,
      };
      excelArray.push(obj);
    });
    this.excelService.exportAsExcelFile(excelArray, this.excelName, true);
  }
  saveOrder() {
    let code;
    if (
      this.orderDetailsData?.guid !== undefined &&
      this.orderDetailsData?.guid !== ''
    ) {
      code = this.orderDetailsData?.guid;
    } else {
      code = this.orderDetailsData?.code;
    }
    this.orderService.saveCartOrderDetails(
      this.userData.userName,
      code,
      this.saveCartName
    );
    this.saveOrderSubscription =
      this.orderService.saveCartOrderSuccess$.subscribe((res) => {
        if (res) {
          this.currentPopover.close();
        }
      });
    this.invalidOrderSubscription =
      this.orderService.invalidOrderCartName$.subscribe((res) => {
        if (res) {
          this.errorMsg = _.pluck(res?.error?.errors, 'message');
          this.errorMsg =
            this.errorMsg === 'This field is required.'
              ? 'Cart name cannot be blank'
              : this.errorMsg;
        }
      });
  }
  getCartId(value) {
    if (this.cartAllItems === undefined) {
      this.orderService.createCart(this.userData?.userName);
      this.createCartSubscription =
        this.orderService.createdOrderCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.orderService.getCarts(this.userData?.userName);
            this.addToCarts(value);
          }
        });
    } else {
      if (this.cartId === undefined) {
        this.cartId = this.cartAllItems?.code;
      }
      this.addToCarts(value);
    }
  }
  addToCarts(value) {
    const data = [];
    if (this.orderDetailsData?.selectedWholesaler?.uid) {
      this.isIndirectOrder = true;
    }
    this.orderDetailsData.entries.forEach((element) => {
      if (element.product.displayProduct) {
        data.push({
          entryNumber: 0,
          productCode: element.product.code,
          quantity: element.quantity,
          selectedB2BUnit: this.isIndirectOrder
            ? this.orderDetailsData.selectedWholesaler.uid
            : null,
        });
      }
    });
    const objArr = {
      addToCartForm: data,
    };
    this.orderService.addToCart(this.userData.userName, objArr, this.cartId);
    this.addCartDetailsSubscription =
      this.orderService.addCartDetails$.subscribe((res) => {
        if (res !== false && res !== null) {
          if (value === 'clearCart') {
            this.modalReference.close();
          }
          this.dataLayerService.addReservationCartDataLayer(
            res,
            'standard process',
            null,
            'order detail'
          );
          setTimeout(() => {
            if (this.isIndirectOrder) {
              this.routingService.goByUrl('/indirect/cart');
            } else {
              this.routingService.goByUrl('/cart');
            }
          }, 1000);
        }
      });
  }
  clearChildCart(): void {
    this.orderService.clearCart(
      this.standardCartDetails.code,
      this.userData.userName
    );
    this.deleteOrderSubscription =
      this.orderService.deleteOrderItems$.subscribe((res) => {
        if (res) {
          this.getCartId('clearCart');
          this.orderService.deleteOrderItems.next(false);
        }
      });
  }

  backToCategory() {
    this.influenzaService.contiueShopping = true;
    this.router.navigateByUrl('/influenza/category');
  }

  fluEditReserve(totalMinDosesThreshold: number): void {
    this.user.get().subscribe((userData) => {
      this.userId = userData.uid;
      if (this.userId) {
        this.influenzaService
          .getFluPreOrderUpdate(this.userId, this.reservationId)
          .subscribe((response) => {
            this.fluOrderData = response;
            // Pass the response and minThreshold to the navigation state
            if (response) {
              this.router.navigate(['/influenza/category'], {
                state: {
                  editReservefluData: response,
                  minThreshold: totalMinDosesThreshold,
                },
              });
            }
          });
        this.influenzaService.setInfluenzaOrderId(this.reservationId);
        this.influenzaService.setFluReserve(null);
        this.influenzaService.setInfluenzaThreshold(null);
        sessionStorage.setItem('isAmendmentCart', 'true');
      }
    });
  }

  fluEditButtonConfig(orderDetailsData) {
    if (
      this.orderDetailsData?.cartType === 'INFLUENZA' && this.userData
    ) {
      this.editButtonConfig = this.influenzaService.getEditFluPreOrderConfig(
        orderDetailsData?.user?.uid,
        orderDetailsData?.guid
      );
      this.cd.detectChanges();
    }
  }

  checkDateRange(orderDetailsData): void {
    if (
     this.orderDetailsData?.cartType === 'INFLUENZA' && this.userData
    ) {
      this.editButtonConfig.subscribe((data) => {
        const currentDate = new Date();
        const fromDate = data?.editFrom ? new Date(data?.editFrom) : null;
        const toDate = data?.editTo ? new Date(data?.editTo) : null;
        if (
          fromDate &&
          toDate &&
          currentDate >= fromDate &&
          currentDate <= toDate
        ) {
          this.showButton = true;
        } else {
          this.showButton = false;
        }
      });
    }
  }

  getProductName(name) {
    return name ? this.sanitizer.bypassSecurityTrustHtml(name) : '';
  }

  hasCancelledQuantities(): boolean {
    return this.cancelledEntries.some((entry) => entry.quantity > 0);
  }

  getDescription(description) {
    return description
      ? this.sanitizer.bypassSecurityTrustHtml(description)
      : '';
  }

  checkEntriesExist(orderEntry): boolean {
    return orderEntry.unconsignedEntries?.length > 0 || orderEntry.prodConsignments?.length > 0;
  }

  print() {
    window.print();
  }
  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    this.orderCartSubscription?.unsubscribe();
    this.orderDetailSubscription?.unsubscribe();
    this.addToCartSubscription?.unsubscribe();
    this.userAccountSubscription?.unsubscribe();
    this.userDetailSubscription?.unsubscribe();
    this.saveOrderSubscription?.unsubscribe();
    this.invalidOrderSubscription?.unsubscribe();
    this.createCartSubscription?.unsubscribe();
    this.addCartDetailsSubscription?.unsubscribe();
    this.deleteOrderSubscription?.unsubscribe();
    this.currentLangSub?.unsubscribe();
    this.cartIdSubscriber?.unsubscribe();
  }

  downloadFile(downloadUrl, name) {
    if (downloadUrl && downloadUrl.url) {
      const pdfUrl = `${this.baseUrl}${downloadUrl.url}`;
      const pdfName = `${name}`;
      const FileSaver = require('file-saver');
      FileSaver.saveAs(pdfUrl, pdfName);
    }
  }
}
