import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordian-answers',
  templateUrl: './accordian-answers.component.html'
})
export class AccordianAnswersComponent implements OnInit {
  @Input() answers: any;
  expandAll = true;
  answerList = [];
  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.answerList = this.answers.split('\\n');
    this.cd.detectChanges();
  }
}
