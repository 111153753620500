import { Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';

@Component({
  selector: 'app-need-to-go-back',
  templateUrl: './need-to-go-back.component.html'
})
export class NeedToGoBackComponent implements OnInit {

  cmsContent: any;

  constructor(
    private readonly cmsData: CmsService
  ) { }

  ngOnInit(): void {
    this.cmsData.getComponentData("NeedAssistanceComponent-orders").subscribe(res => {
      const needAssistanceContent: any = JSON.parse(JSON.stringify(res));
      this.cmsContent = needAssistanceContent?.content
    })
  }

}
