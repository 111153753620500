import { Component, OnInit,Input } from '@angular/core';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { AccountType } from "../../custom-user-registration/registration.constants";

@Component({
  selector: 'app-flu-header',
  templateUrl: './flu-header.component.html',
  styleUrls: ['./flu-header.component.scss']
})
export class FluHeaderComponent implements OnInit {
  @Input() selectedHeader: string;
  @Input() tableCategory: string;
  hospital: boolean;
  constructor(private readonly influenzaService:CustomInfluenzaService) { }

  ngOnInit() {
    this.hospital = sessionStorage.getItem('accountType') === AccountType.Hospital;
  }

}
