import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { GlobalMessageService, GlobalMessageType, LanguageService, } from '@spartacus/core';
import { SubscriptionsContainer } from 'src/app/shared/utils/subscriptions-container';
import { VscaAuthService } from '../../../../core/auth/user-auth/facade/vsca-auth.service';
import { NumberEight, NumberThree, NumberTwo, } from '../../../shared/utils/constants/global.constants';
import { CustomRegistrationService } from '../../custom-registration.service';
import { AccountType, globalErrorMsgEng, globalErrorMsgFr, siteLanguagesEng, } from '../../registration.constants';

const emailRegexExp =
  '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$';
const taxNumberRegexExp = '[0-9a-zA-Z]+$';
const accountNumberRegexExp = '[0-9]+$';



@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss'],
})
export class AccountInformationComponent implements OnInit , OnDestroy {
  inputValue: string = '';
  accTypeRegistration: boolean = false;
  invalidSiretNumberError: boolean = false;

  checkLength(): void {
    const length = this.accountInfoForm.controls.accountNumber.value.length;
    if (length >= 14 && length < 15 && this.inputValue) {
      this.accountRegService.getValidateAccountTypeData(this.inputValue).subscribe((res) => {
        const userAccountType = res?.customerAccountType
        if(userAccountType === 'PHARMACY'){
          this.accTypeRegistration = true;
          this.accountTypeChange(AccountType.Pharmacy)
        }
        else if(userAccountType === 'HOSPITAL'){
          this.accTypeRegistration = true;
          this.accountTypeChange(AccountType.Hospital)
        }
        else{
          this.invalidSiretNumberError = true;
        }
        this.cdr.detectChanges();
      })
      this.invalidSiretNumberError = false;
    } else {
      this.accTypeRegistration = false;
      this.invalidSiretNumberError = true;
    }
  }
  
  accountInfoForm: FormGroup;
  errorMsg = false;
  isNextButtonPressed: boolean = false;
  isAccountValidated: boolean = false;
  errorTypes: string[] = [
    'b2bCustomer.register.accountNumber.invalidType',
    'b2bCustomer.register.email.exists',
    'b2bCustomer.register.akamai.email.exists',
    'b2bCustomer.register.akamai.login.failed',
    'b2bCustomer.register.akamai.password.empty',
    'b2bCustomer.register.accountType.invalidType',
    'b2bCustomer.register.postalCode.invalid',
    'b2bCustomer.register.accountNumber.deleted',
  ];
  billToShipError = false;
  accountTypeError = false;
  accountIsDeleted = false;
  errorMessageText: string = globalErrorMsgEng;
  isEmailExists = false;
  isPasswordEmptyInAkamai = false;
  isIndirectB2BUnitValidated = false;
  akamaiPasswordValidationError = false;
  isPasswordValid = true;
  userAuthDetails: any;
  janrainUserData: any;
  skipAkamaiValidation = false;
  roles: any;
  filteredRoles: any[];
  accountType: AccountType;
  selectedRoleHcp: boolean;
  subs = new SubscriptionsContainer();
  invalidPostCodeError: boolean = false;
  akamaiResetUrl: string = "https://pro.campus.sanofi/fr/connexion/reinitialisation-du-mot-de-passe";
  captchaResp: string;


  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly languageService: LanguageService,
    private readonly globalMessage: GlobalMessageService,
    private readonly auth: VscaAuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly ref: ChangeDetectorRef
  ) {
    this.accountRegService.getRoleTypes();
  }

  ngOnInit(): void {
    
    this.accountType = AccountType.Pharmacy;

    this.subs.add = this.accountRegService.getRoleTypes().subscribe((roles) => {
      if (roles) {
        this.roles = roles;
        this.filteredRoles = this.filterRolesByType(this.accountType, this.roles);
        this.ref.detectChanges();
      }
    });
    window.scroll(0, 0);
    const maxlengthChar = 20;
    const maxlengthCharForSiret = 14;
    const maxlengthFive = 5;
    this.subs.add = this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.accountInfoForm = new FormGroup({
      accountType: new FormControl(AccountType.Pharmacy),
      hospital:new FormControl(false),
      accountAlias: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]+$'),
        Validators.maxLength(maxlengthCharForSiret),
      ]),
      accountNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]+$'),
        Validators.maxLength(maxlengthCharForSiret),
      ]),
      rolesFlag: new FormControl('default', [Validators.required]),
      wholeSalerFlag: new FormControl(null),
      role: new FormControl(null),
      registeredAkamaiUser: new FormControl(false),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.pattern('^([0-9]{5})$'),
        Validators.maxLength(maxlengthFive),
      ]),
      emailInfo: new FormGroup(
        {
          email: new FormControl('', [
            Validators.required,
            Validators.pattern(emailRegexExp),
          ]),
          confirmEmail: new FormControl('', [
            Validators.required,
            Validators.pattern(emailRegexExp),
          ]),
        },
        { validators: [this.emailMatchValidator] }
      ),
      userInfoDetails: new FormGroup(
        {
          password: new FormControl('', []),
          confirmPassword: new FormControl('', []),
        },
        {
          validators: [],
        }
      ),
      recaptchaReactive: new FormControl(null, Validators.required),
    });
    this.subs.add =
      this.accountInfoForm.controls.wholeSalerFlag.valueChanges.subscribe(
        (active) => {
          this.accountInfoForm.controls.accountNumber.clearValidators();
          if (active === true) {
            this.accountInfoForm.controls.accountNumber.setValidators([
              Validators.required,
              Validators.pattern(taxNumberRegexExp),
              Validators.maxLength(maxlengthChar),
            ]);
          } else {
            this.accountInfoForm.controls.accountNumber.setValidators([
              Validators.required,
              Validators.pattern(accountNumberRegexExp),
              Validators.maxLength(maxlengthChar),
            ]);
          }
          this.accountInfoForm.controls.accountNumber.updateValueAndValidity();
        }
      );
    this.userAuthDetails = this.isEmptyObject(this.auth.userAuthDetails)
      ? undefined
      : this.auth.userAuthDetails;
    this.dropDownFromAkamaiIfExist();

    let eregistrationToken: any;
    this.activatedRoute.queryParams
      .subscribe(userParams => {
        eregistrationToken = userParams.registration;
        }
      );

    if(eregistrationToken){
      this.subs.add = this.accountRegService.getRegistrationParams(eregistrationToken).subscribe((userParams) => {
        if (userParams) {
         this.accountInfoForm.controls.accountNumber.setValue(userParams.siretCode);
         this.accountInfoForm.controls.postalCode.setValue(userParams.postalCode);
         this.accountInfoForm.controls.accountAlias.setValue(userParams.erpCode);
         this.roles.forEach((roles) => {
           if (roles.code === userParams.role) {
             this.accountInfoForm.get('rolesFlag').setValue(roles);
           }
          });
          this.changeRole();
          this.emailInfoFormGroup.controls.email.setValue(userParams.email);
          this.ref.detectChanges();
        }
      });
    }
  }
  accountTypeChange(type: AccountType): void {
    this.accountType = type;
    this.accountInfoForm.get('accountType').setValue(type);
    this.accountInfoForm.get('hospital').setValue(type === AccountType.Hospital);

    this.filteredRoles = this.filterRolesByType(this.accountType, this.roles);

    // Reset role selection on accountTypeChange
    this.accountInfoForm.get('rolesFlag').setValue("default");
    this.accountInfoForm.get('wholeSalerFlag').setValue(false);
    this.accountInfoForm.get('role').setValue(null);
    this.selectedRoleHcp = false;
  }

  filterRolesByType(type: AccountType, roles: []): [] {
    if (type === AccountType.Pharmacy) {
      return this.filteredRoles = this.roles.filter(r => {
        return !!r.pharmacy;
      })
    } else if (type === AccountType.Hospital) {
      return this.filteredRoles = this.roles.filter(r => {
        return !!r.hospital;
      })
    }

    return roles;
  }

  changeRole(): void {
    const role = this.accountInfoForm.get('rolesFlag').value;
    const selectedRoleHcp = [];
    this.roles.forEach((roles) => {
      if (roles.name === role.name) {
        selectedRoleHcp.push(roles);
      }
    });
    selectedRoleHcp[0].hcp === true
      ? (this.selectedRoleHcp = true)
      : (this.selectedRoleHcp = false);
    this.accountInfoForm.get('wholeSalerFlag').setValue(this.selectedRoleHcp);
    this.accountInfoForm.get('role').setValue(role.code);
  }

  validatePassword(password: FormControl): { [s: string]: boolean } {
    let counter = 0;
    let lengthCounterFlag = false;
    let nonSpecialCounterFlag = false;
    let specialCounterFlag = false;
    const str = password.value;

    // On each test that is passed, increment the counter
    if (/[a-z]/.test(str)) {
      // If string contain at least one lowercase alphabet character
      counter++;
    }
    if (/[A-Z]/.test(str)) {
      counter++;
    }
    if (/[0-9]/.test(str)) {
      counter++;
    }
    if (/[^a-zA-Z0-9]/.test(str)) {
      nonSpecialCounterFlag = true;
    }

    if (str.length >= NumberEight) {
      lengthCounterFlag = true;
    }

    const specialChars = this.getAllSpecialChars(str);

    if (nonSpecialCounterFlag && /^[*$%!)(&^]+$/.test(specialChars)) {
      specialCounterFlag = true;
    }
    // Check if at least three rules are satisfied
    this.checkAllRulesForPassword(
      counter,
      lengthCounterFlag,
      nonSpecialCounterFlag,
      specialCounterFlag
    );

    if (!this.isPasswordValid) {
      return { isPasswordInvalid: true };
    } else {
      return null;
    }
  }

  checkAllRulesForPassword(
    counter,
    lengthCounterFlag,
    nonSpecialCounterFlag,
    specialCounterFlag
  ) {
    if (
      (counter === NumberThree &&
        lengthCounterFlag &&
        !nonSpecialCounterFlag) ||
      (counter >= NumberTwo &&
        lengthCounterFlag &&
        nonSpecialCounterFlag &&
        specialCounterFlag)
    ) {
      this.isPasswordValid = true;
    } else {
      this.isPasswordValid = false;
    }
  }

  getAllSpecialChars(str: string): string {
    const strAr = str.split('');
    const noSpecialChars = '';
    const resArr = [];
    for (const strItem of strAr) {
      if (/[^a-zA-Z0-9]/.test(strItem)) {
        resArr.push(strItem);
      }
    }
    if (resArr.length > 0) {
      return resArr.join('');
    } else {
      return noSpecialChars;
    }
  }

  pwdMatchValidator(frm: FormGroup) {
    if (!!frm) {
      return frm.get('password').value === frm.get('confirmPassword').value
        ? null
        : { mismatch: true };
    } else {
      return null;
    }
  }

  get emailInfoFormGroup() {
    return this.accountInfoForm.get('emailInfo') as FormGroup;
  }

  get userInfoDetailsGroup() {
    return this.accountInfoForm.get('userInfoDetails') as FormGroup;
  }

  emailMatchValidator(frm: FormGroup) {
    if (!!frm) {
      if (frm.get('email').value !== frm.get('confirmEmail').value) {
        return { emailNotMatch: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  validateAccountInfo() {
    this.isNextButtonPressed = true;
    if (this.accountInfoForm.get('rolesFlag').value !== 'default') {
      if (
        this.accountInfoForm.get('wholeSalerFlag').value &&
        (this.isIndirectB2BUnitValidated || this.skipAkamaiValidation === true)
      ) {
        this.validateIndirectAccountInAkamai();
      } else if (
        this.accountInfoForm.get('wholeSalerFlag').value &&
        !this.isIndirectB2BUnitValidated
      ) {
        this.validateIndirectAccountInfo();
      } else {
        this.validateDirectAccountInfo();
      }
    }
  }

  validateIndirectAccountInfo() {
    this.accountInfoForm.markAllAsTouched();
    this.accountInfoForm.controls.emailInfo.clearValidators();
    this.emailInfoFormGroup.controls.email.setValidators([
      Validators.required,
      Validators.pattern(emailRegexExp),
    ]);
    this.emailInfoFormGroup.controls.confirmEmail.clearValidators();
    this.accountInfoForm.controls.emailInfo.updateValueAndValidity();
    this.emailInfoFormGroup.controls.email.updateValueAndValidity();
    this.emailInfoFormGroup.controls.confirmEmail.updateValueAndValidity();
    if (this.accountInfoForm.valid) {
      this.subs.add = this.accountRegService
        .postValidateIndirectAccountInfo(this.getUserDataForRequest())
        .subscribe(
          (res) => {
            if (res === null) {
              this.errorMsg = false;
              this.isIndirectB2BUnitValidated = true;
              this.isAccountValidated = true;
              this.cdr.detectChanges();
            }
          },
          (error) => {
            if (!!error) {
              this.errorMsg = true;
              this.checkIndirectErrors(error);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              if (this.errorMsg) {
                window.scroll(0, 0);
                this.globalMessage.add(
                  this.errorMessageText,
                  GlobalMessageType.MSG_TYPE_ERROR
                );
              }
              this.cdr.detectChanges();
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  validateIndirectAccountInAkamai() {
    this.accountInfoForm.markAllAsTouched();
    if (
      this.accountInfoForm.get('registeredAkamaiUser').value ||
      this.skipAkamaiValidation
    ) {
      this.accountInfoForm.controls.emailInfo.clearValidators();
      this.emailInfoFormGroup.controls.email.setValidators([
        Validators.required,
        Validators.pattern(emailRegexExp),
      ]);
      this.emailInfoFormGroup.controls.confirmEmail.clearValidators();
    } else {
      this.accountInfoForm.controls.emailInfo.setValidators([
        Validators.required,
        this.emailMatchValidator,
      ]);
      this.emailInfoFormGroup.controls.email.setValidators([
        Validators.required,
        Validators.pattern(emailRegexExp),
      ]);
      this.emailInfoFormGroup.controls.confirmEmail.setValidators([
        Validators.required,
        Validators.pattern(emailRegexExp),
      ]);
      this.accountInfoForm.controls.userInfoDetails.setValidators([
        this.pwdMatchValidator,
      ]);
      this.userInfoDetailsGroup.controls.password.setValidators([
        Validators.required,
        this.validatePassword.bind(this),
      ]);
      this.userInfoDetailsGroup.controls.confirmPassword.setValidators([
        Validators.required,
        this.validatePassword.bind(this),
      ]);
    }

    this.accountInfoForm.controls.emailInfo.updateValueAndValidity();
    this.emailInfoFormGroup.controls.email.updateValueAndValidity();
    this.emailInfoFormGroup.controls.confirmEmail.updateValueAndValidity();
    this.accountInfoForm.controls.userInfoDetails.updateValueAndValidity();
    this.userInfoDetailsGroup.controls.password.updateValueAndValidity();
    this.userInfoDetailsGroup.controls.confirmPassword.updateValueAndValidity();

    if (this.accountInfoForm.valid) {
      this.subs.add = this.accountRegService
        .postValidateIndirectAccountInAkamai(this.getUserDataForRequest())
        .subscribe(
          (res) => {
            if (res === null) {
              this.errorMsg = false;
              this.accountInfoForm
                .get('userInfoDetails.confirmPassword')
                .setValue(
                  this.accountInfoForm.get('userInfoDetails.password').value
                );
              this.accountInfoForm
                .get('emailInfo.confirmEmail')
                .setValue(this.accountInfoForm.get('emailInfo.email').value);
                this.isAccountValidated = true;
              const accountInfo = {
                header: 'userInformation',
                form: this.accountInfoForm,
              };
              this.accountRegService.sectionChange.next(accountInfo);
            }
          },
          (error) => {
            if (error) {
              this.errorMsg = true;
              this.checkIndirectErrorsFromAkamai(error);
              window.scroll(0, 0);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              this.globalMessage.add(
                this.errorMessageText,
                GlobalMessageType.MSG_TYPE_ERROR
              );
              this.cdr.detectChanges();
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  setUserDetails(formDta, userDetails): any {
    for (const key in formDta) {
      if (key !== 'userInfoDetails' && key !== 'emailInfo') {
        userDetails[key] = formDta[key];
      }
    }
    return userDetails;
  }

  getUserDataForRequest(): any {
    let userDetails = {};
    const formDta = { ...this.accountInfoForm.value };
    if (!!formDta) {
      userDetails = this.setUserDetails(formDta, userDetails);
    }
    const userData = {
      ...userDetails,
      ...this.accountInfoForm.get('userInfoDetails').value,
      ...this.accountInfoForm.get('emailInfo').value,
      'hospital': this.accountInfoForm.get('accountType').value === AccountType.Hospital,
    };
    delete userData?.recaptchaReactive;
    return userData;
  }

  resolved(captchaResponse: string) {
    this.captchaResp = captchaResponse;
  }

  validateDirectAccountInfo() {
    this.accountInfoForm.markAllAsTouched();
    this.emailInfoFormGroup.controls.email.clearValidators();
    this.emailInfoFormGroup.controls.confirmEmail.clearValidators();
    this.accountInfoForm.controls.emailInfo.clearValidators();
    this.accountInfoForm.controls.emailInfo.updateValueAndValidity();
    this.emailInfoFormGroup.controls.email.updateValueAndValidity();
    this.emailInfoFormGroup.controls.confirmEmail.updateValueAndValidity();
    if (this.accountInfoForm.valid) {
      this.subs.add = this.accountRegService
        .postValidateAccountInfo(this.getUserDataForRequest())
        .subscribe(
          (res) => {
            if (res === null) {
              this.errorMsg = false;
              this.isAccountValidated = true;
              const accountInfo = {
                header: 'userInformation',
                form: this.accountInfoForm,
              };
              this.accountRegService.sectionChange.next(accountInfo);
            }
          },
          (error) => {
            if (!!error) {
              if (this.accountInfoForm.get('rolesFlag').value !== 'default') {
                this.errorMsg = true;
              }
              this;
              this.checkErrors(error);
              window.scroll(0, 0);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              this.globalMessage.add(
                this.errorMessageText,
                GlobalMessageType.MSG_TYPE_ERROR
              );
              this.cdr.detectChanges();
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  checkErrors(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      this.billToShipError =
        error?.error?.errors[0].message === this.errorTypes[0] ? true : false;
      this.accountIsDeleted = error?.error?.errors[0].message === this.errorTypes[7] ? true : false;
      this.accountTypeError =
        error?.error?.errors[0].message === this.errorTypes[5] ? true : false;
      if (this.accountTypeError) {
        this.errorMsg = false;
      }
    }
  }

  checkIndirectErrors(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      this.billToShipError =
        error?.error?.errors[0].message === this.errorTypes[0] ? true : false;
      this.isEmailExists =
        error?.error?.errors[0].message === this.errorTypes[1] ? true : false;
      this.isPasswordEmptyInAkamai =
        error?.error?.errors[0].message === this.errorTypes[4] ? true : false;
      this.accountIsDeleted = error?.error?.errors[0].message === this.errorTypes[7] ? true : false;
      this.accountInfoForm
        .get('registeredAkamaiUser')
        .setValue(
          error?.error?.errors[0].message === this.errorTypes[2] ? true : false
        );

      if (
        !this.billToShipError &&
        !this.isEmailExists &&
        this.accountInfoForm.get('registeredAkamaiUser').value &&
        !this.isPasswordEmptyInAkamai
      ) {
        this.isIndirectB2BUnitValidated = true;
        this.errorMsg = false;
      } else if (this.isPasswordEmptyInAkamai) {
        this.isIndirectB2BUnitValidated = false;
        this.errorMsg = false;
        localStorage.setItem("passwordReset", "true");
      } else {
        this.isIndirectB2BUnitValidated = false;
      }
    }
  }

  checkIndirectErrorsFromAkamai(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      this.akamaiPasswordValidationError =
        error?.error?.errors[0].message === this.errorTypes[3] ? true : false;
      this.invalidPostCodeError =
        error?.error?.errors[0].message === this.errorTypes[6] ? true : false;
    }
  }

  dropDownFromAkamaiIfExist() {
    if (
      this.accountInfoForm.get('wholeSalerFlag')?.value &&
      this.accountInfoForm.get('rolesFlag').value !== 'default'
    ) {
      this.accountInfoForm
        .get('wholeSalerFlag')
        .setValue(!!this.userAuthDetails);
        
      this.subs.add = this.accountRegService
        .getJanrainUserDataByUserName(this.userAuthDetails?.userId)
        .subscribe((res) => {
          if (res) {
            this.janrainUserData = JSON.parse(JSON.stringify(res));
            if (!!this.janrainUserData && !!this.janrainUserData?.userName) {
              this.accountInfoForm
                .get('emailInfo.email')
                .setValue(this.janrainUserData?.email);
              this.skipAkamaiValidation = true;
              this.accountInfoForm.get('registeredAkamaiUser').setValue(true);
            }
            this.cdr.detectChanges();
          }
        });
    }
    this.userInfoDetailsGroup
      .get('password')
      .setValue(this.userAuthDetails?.password);
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  ngOnDestroy() {
    this.auth.userAuthDetails = undefined;
    this.subs.dispose();
  }

  protected readonly AccountType = AccountType;
}
