import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';

@Component({
  selector: 'app-faq-title',
  templateUrl: './faq-title.component.html'
})
export class FaqTitleComponent implements OnInit {
  cmsContent: any;

  constructor(
    private readonly cmsData: CmsService,
    public readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cmsData.getComponentData('faqTextParagraph').subscribe((res) => {
      const faqTextParagraph: any = JSON.parse(JSON.stringify(res));
      this.cmsContent = faqTextParagraph.content;
      this.cd.detectChanges();
    });
  }
}
