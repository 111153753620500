import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, OccEndpointsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { CheckoutService } from 'src/app/shared/modules/checkout/checkout.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { AccountType } from "../../custom-user-registration/registration.constants";
import { CustomInfluenzaService } from '../custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';

@Component({
  selector: 'app-flu-reservation-details',
  templateUrl: './flu-reservation-details.component.html',
  styleUrls: ['./flu-reservation-details.component.scss']
})
export class FluReservationDetailsComponent implements OnInit,OnDestroy {
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  totalProducts: any;
  totalDoses: number;
  estTotal: any;
  dataForFluConfirmation: any = [];
  productEntries: any = [];
  deliverySchedules: any = [];
  salesTax = 0;
  currencyIso: any;
  totalwithSavings: number;
  subTotal: any;
  directSavings: any;
  onlineSavings: any;
  baseUrl: any;
  duplicateProductCheckArray: any = [];
  displayProductForUiArray: any = [];
  reservationId:any='';
  statusDisplay:any='';
  seasonId:any='';
  detailedResSubscriber: any;
  estimatedDeliveryDate: any;
  orderData: any;
  hospital: boolean;
  editButtonConfig: Observable<any>;
  showButton: boolean = false;
  cartIdSubscriber: any;
  cartSubscriber: any;
  activeCartId = '';
  userId = '';
  fluOrderData: any;
  fluOrderConfigData: any;

  constructor(private readonly checkoutService: CheckoutService,
    private readonly router: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly influenzaService: CustomInfluenzaService, private readonly ref: ChangeDetectorRef,
    private authService: AuthService,
    private dataLayerService: DataLayerService,
    private readonly user: UserAccountFacade,
    private readonly navigateRouter: Router,
    private readonly modalService: NgbModal) { }


  ngOnInit() {
    this.hospital = sessionStorage.getItem('accountType') === AccountType.Hospital;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.detailedResSubscriber=this.influenzaService.detailedReservation.subscribe((data) => {
      if (data) {
        this.orderData = data;
        this.dataForFluConfirmation = [];
        this.dataForFluConfirmation.push(data);
      }
      this.detailedResSubscriber?.unsubscribe();
    });
    if (this.dataForFluConfirmation) {
      this.dataForFluConfirmation.forEach((element, index) => {
        this.reservationId = element?.guid;
        this.statusDisplay = element.statusDisplay;
        this.seasonId = element.seasonId;
        if (this.seasonId){
          this.seasonId = this.seasonId.replace('-', '/');
        }
        this.estTotal = element?.totalPriceWithTax?.value;
        this.currencyIso = element?.totalPriceWithTax?.currencyIso;
        this.salesTax = element?.totalTax?.value;
        this.directSavings = element?.productDiscounts?.value;
        this.onlineSavings = element?.totalDiscounts?.value;
        this.totalwithSavings = element.totalPrice?.value;
        this.subTotal = element?.subTotal?.value;
        this.productEntries = [];
        this.deliverySchedules = [];
        this.totalDoses = 0;
        this.totalProducts = 0;
        this.productEntries = element?.entries;
        this.deliverySchedules = element?.deliverySchedules;
        this.duplicateProductCheckArray = [];
        this.displayProductForUiArray = [];
        if (this.productEntries) {
          this.productEntries?.forEach((elem) => {
            if(elem?.estimatedDeliveryDate){
            this.estimatedDeliveryDate=elem?.estimatedDeliveryDate;

            }
            const product = elem.product;
            let displayobj = {};
            if (this.duplicateProductCheckArray?.indexOf(elem?.product?.code) === -1) {
              this.duplicateProductCheckArray.push(elem.product.code);
              let thumbNailImg = '';
              displayobj = {
                'prodCode': elem?.product?.code,
                'prodName': elem?.product?.name,
                'cipCode': elem?.product?.cipCode,
                'basePrice': elem?.basePrice?.value,
                'quantity': elem?.quantity,
                'doses': elem?.product?.quantityPerUnit,
                'onsn': elem?.product?.onsn,
                'images': elem?.product?.images,
                'catalogPrice': elem?.catalogPrice,
                'price': elem?.basePrice,
                'discontinuedFlag': elem?.discontinued,
                'description': elem?.product?.description,
                'rejectionReason': elem?.rejectionReason,
                'quantityStatus': elem?.quantityStatus,
                ...product
              };
              elem?.product?.images ? thumbNailImg = elem?.product?.images[2]?.url : '';
              thumbNailImg !== '' ? displayobj['thumbNailImg'] = this.baseUrl + thumbNailImg : displayobj['thumbNailImg'] = '';
              this.displayProductForUiArray.push(displayobj);
            }

            this.totalProducts += parseInt(elem?.quantity);
            const a = parseInt(elem?.quantity);
            this.totalDoses += a;
          });
        }
        this.updateQuantities(this.displayProductForUiArray, this.deliverySchedules);
        this.ref.detectChanges();
      });
    }

    this.fluEditButtonConfig();
    this.ref.detectChanges();
    this.checkDateRange();
  }
  ngOnDestroy(){
    this.detailedResSubscriber?.unsubscribe();
  }

  updateQuantities(displayProductForUiArray, deliverySchedules): void {
    // Create a Map to store the quantity for each product in deliverySchedules
    const quantityMap: Map<string, number> = new Map();

    // Sum quantities of the same product code in deliverySchedules
    deliverySchedules
      .filter(delivery => !delivery.cancellationReason)
      .forEach(delivery => {
      const currentQuantity = quantityMap.get(delivery.product?.code) || 0;
      quantityMap.set(delivery.product?.code, currentQuantity + delivery.requestedQuantity);
    });

    // Update quantities in displayProductForUiArray based on the summed values
    displayProductForUiArray.forEach(displayProduct => {
      if (quantityMap.has(displayProduct.prodCode)) {
        displayProduct.quantity = quantityMap.get(displayProduct.prodCode)!; // `!` asserts that the value is not null/undefined
      }
    });
  }

  OnFluPrint(){
    window.print();
  }

  backToDashBoard() {
    this.router.goByUrl('/influenza/dashboard');
  }

  productDetails(productDetails) {
    const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent,
      {windowClass: 'product-details-inf-modal'});
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = productDetails.code;
  }

  backToCategory() {
    this.influenzaService.contiueShopping = true;
    this.router.goByUrl('/influenza/category');
  }

fluEditReserveUpdate(totalMinDosesThreshold: number) {
  this.user.get().subscribe((data) => {
    this.userId = data.uid;
    if (this.userId) {
      this.influenzaService.getFluPreOrderUpdate(this.userId, this.reservationId).subscribe(
        (response) => {
          this.fluOrderData = response;
          if (response) {
            this.navigateRouter.navigate(['/influenza/category'], {
              state: {
                editReservefluData: response,
                minThreshold: totalMinDosesThreshold,
              },
            });
          }
        },
        (error) => {
          console.error('Error during flu pre-order update:', error);
        }
      );
      this.influenzaService.setInfluenzaOrderId(this.reservationId);
      this.influenzaService.setFluReserve(null);
      this.influenzaService.setInfluenzaThreshold(null);
      sessionStorage.setItem('isAmendmentCart', 'true');
    }
  });
 }

  fluEditButtonConfig(){
    this.user.get().subscribe((userData) => {
      this.editButtonConfig = this.influenzaService.getEditFluPreOrderConfig(userData?.uid, this.reservationId);
    });
  }

  checkDateRange(): void {
    this.editButtonConfig.subscribe((data) => {
      const currentDate = new Date();
      const fromDate = data.editFrom ? new Date(data.editFrom) : null;
      const toDate = data.editTo ? new Date(data.editTo) : null;
      if (fromDate && toDate && currentDate >= fromDate && currentDate <= toDate) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    });
  }
}
