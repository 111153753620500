import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountFacade } from "@spartacus/user/account/root";
import * as html2pdf from 'html2pdf.js';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { MakeAPaymentService } from '../../custom-make-a-payment/make-a-payment.service';
import * as config from "../../shared/config/app.config";
import { BaseService } from "../../shared/services/base.service";
import {
  NumberTen,
  TwoThousand,
} from '../../shared/utils/constants/global.constants';
import { OpenStatementsService } from '../open-statements.service';

@Component({
  selector: 'app-statement-display',
  templateUrl: './statement-display.component.html',
  styleUrls: ['./statement-display.component.scss'],
  host: {
    '[style.overflow]': '"hidden"',
    '[style.width]': '"100%"',
  },
})
export class StatementDisplayComponent implements OnInit, OnDestroy {
  @Input() toShow: boolean;
  @Input() statement: any;
  someInputField: any;
  allStatementData: any;
  accountNumber = null;
  accountIs2kNumber: any;
  sapCustomerId: any;
  accountName: any;
  billingAddress: any;
  systemId: any;
  loadSub: Subscription;
  userAccSubs: Subscription;
  allStatementsSub: Subscription;
  showPopUp: boolean;
  isPDF = false;

  constructor(
    private readonly stService: OpenStatementsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly makeAPayment: MakeAPaymentService,
    private readonly user: UserAccountFacade,
    private readonly baseService: BaseService,
    public activeModal?: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.showPopUp = false;
    this.allStatementData = [];
    this.cdr.detectChanges();
    this.stService.getAccountId();
    this.userAccSubs = this.stService.userAccountIdDetails.subscribe((res) => {
      if (!this.accountNumber) {
        this.accountNumber = res.defaultB2BUnit.uid;
        this.accountIs2kNumber = res?.defaultB2BUnit?.is2kUid;
        this.sapCustomerId = res?.defaultB2BUnit?.sapCustomerId;
        this.accountName = res?.defaultB2BUnit?.name;
        this.billingAddress = res?.defaultB2BUnit?.billingAddress;
        this.systemId = this.statement?.system;
        this.getAllStatements();
      }
    });

    this.loadSub = this.stService.loadStatements.subscribe((res) => {
      if (!!res !== false) {
        this.getAllStatements();
      }
    });
  }

  checkNegativeValue(data, checkNegative = false) {
    return checkNegative ? Number(data) < 0 : (data?.transType === 'Credit Memo' || data?.transType === 'Note de Crédit');
  }

  generateSavePdf() {
    this.isPDF = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      const options = {
        margin: [NumberTen, NumberTen],
        filename: 'statement.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pagebreak: { avoid: ['.lower-section', '.table-row-section'] },
      };
      const element: Element = document.getElementById('productList-areax');
      html2pdf().from(element).set(options).save();
    }, TwoThousand);
  }

  generateInvoicePDF(invoiceNo) {
    this.makeAPayment.downloadPdf(invoiceNo);
  }

  onPrint() {

    const contents = $("#productList-areax").html();

    if ($("#printDiv").length === 0) {
      let printDiv = null;
      printDiv = document.createElement('div');
      printDiv.setAttribute('id','printDiv');
      printDiv.setAttribute('class','printable');
      $(printDiv).appendTo('body');
    }

    $("#printDiv").html(contents);

    window.focus();
    window.print();

    $("#printDiv").remove();
  }




  getAllStatements() {
    this.user.get().subscribe((customer: any) => {
      if (customer) {
        const params = {
          accountId: this.accountNumber,
          endDate: this.statement.toDte,
          systemId : this.statement.systemUid,
          fields: 'DEFAULT',
        }
        this.allStatementsSub = this.baseService.get(`${config.GET_ALL_STATEMENTS_DATA_RANGE}?fields=DEFAULT&emulatedUser=${customer.userName}`, params).subscribe((res) => {
          if (res) {
            this.allStatementData = JSON.parse(JSON.stringify(res));
            this.showPopUp = true;
            this.cdr.detectChanges();
            if (this.toShow === false) {
              this.generateSavePdf();
            }
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.showPopUp = false;
    this.loadSub?.unsubscribe();
    this.userAccSubs?.unsubscribe();
    this.allStatementsSub?.unsubscribe();
  }
}
