import { Injectable } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { CustomAddToCartService } from '../custom-add-to-cart/custom-add-to-cart.service';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { DataLayerService } from '../shared/services/data-layer.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';

@Injectable({
  providedIn: 'root',
})
export class IndirectService {
  cartId: any;
  userId: any;
  prescriptionType$: Observable<any> = this.baseService
    .get(`/enumeration?enumerationType=PrescriptionType&sortByOrder=true`)
  patientPathology$: Observable<any> = this.baseService
    .get(`/enumeration?enumerationType=PatientPathology&sortByOrder=true`)
  prescriberSpecialty$: Observable<any> = this.baseService
    .get(`/enumeration?enumerationType=PrescriberSpecialty&sortByOrder=true`)
  preliminaryAgreement$: Observable<any> = this.baseService
    .get(`/enumeration?enumerationType=PreliminaryAgreement&sortByOrder=true`)
  
  public allQuestionnairs = new BehaviorSubject<any>(false);
  allQuestionnairs$ = this.allQuestionnairs.asObservable();
  
  public allDupixientAnswers = new BehaviorSubject<any>([]);
  allDupixientAnswers$ = this.allDupixientAnswers.asObservable();

  public allPraluentAnswers = new BehaviorSubject<any>([]);
  allPraluentAnswers$ = this.allPraluentAnswers.asObservable();
  
  constructor(
    private readonly user: UserAccountFacade,
    private readonly baseService: BaseService,
    private readonly route: RoutingService,
    public excelService: ExcelDownloadService,
    private dataLayerService: DataLayerService,
    private readonly customAddToCartService: CustomAddToCartService
  ) {
    this.user.get().subscribe((res: any) => {
      this.userId = res?.userName;
    });
  }

  updateCartDetails(childCartId, entries, cartId): void {
    this.baseService
      .patch(
        `${config.USER_ACCOUNT}${this.userId}/carts/${cartId}/update/${childCartId}?fields=DEFAULT`,
        entries
      )
      .subscribe((res) => {
        if (res) {
          this.customAddToCartService.cartDetails.next(res);
          this.customAddToCartService.updateSessionCart.next(cartId);
        }
      });
  }
  
  getQuestionnairsTypes(userId, cartId): void {
    this.allQuestionnairs.next(false);
    const newUserId = userId
    if (userId) {
      this.baseService
        .get(
          `${config.USER_ACCOUNT}/${this.userId}/carts/${newUserId}/questionnaires`,
          
        )
        .subscribe((res) => {
          this.allQuestionnairs.next(res);
          if(res.length > 0){
            this.getPraluentAnswers();
            this.getDupixientAnswers();
          }
        });
    }
  }
  getPraluentAnswers():void{
    combineLatest(this.preliminaryAgreement$, this.prescriptionType$,  this.prescriberSpecialty$, ).subscribe(([type, agreement, speciality, ])=>{
      this.allPraluentAnswers.next([type, agreement, speciality ])
    })
  }
  getDupixientAnswers():void{
    combineLatest(this.prescriberSpecialty$, this.patientPathology$, this.prescriptionType$,).subscribe(([type, pathology, speciality])=>{
      this.allDupixientAnswers.next([type, pathology, speciality])
    })
  }
  submitAllQuestionaire(userId, cartId, formData):Observable<any>{
    const data = formData;
    return this.baseService
    .post(`${config.USER_ACCOUNT}${userId}/place-multiple-orders?cartId=${cartId}&fields=DEFAULT`, 
     data)
  }
  
  setLocalData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  getLocalData(key: string) {
    return localStorage.getItem(key);
  }
}
